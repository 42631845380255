import anonP0S1ENLeader from "../../assets/images/anonPage/AnonLeader/anonP0S1ENLeader.png";
import anonP0S1ESPLeader from "../../assets/images/anonPage/AnonLeader/anonP0S1ESPLeader.png";
import anonP0S1PT_BRLeader from "../../assets/images/anonPage/AnonLeader/anonP0S1PT_BRLeader.png";
import anonP0S2AllLangLeader from "../../assets/images/anonPage/AnonLeader/anonP0S2AllLangLeader.png";
import anonP0S3ENLeader from "../../assets/images/anonPage/AnonLeader/anonP0S3ENLeader.png";
import anonP0S3ESPLeader from "../../assets/images/anonPage/AnonLeader/anonP0S3ESPLeader.png";
import anonP0S3PT_BRLeader from "../../assets/images/anonPage/AnonLeader/anonP0S3PT_BRLeader.png";
import anonP0S4AllLangLeader from "../../assets/images/anonPage/AnonLeader/anonP0S4AllLangLeader.png";
import anonP0S5PT_BRLeader from "../../assets/images/anonPage/AnonLeader/anonP0S5PT_BRLeader.png";
import anonP0S5ENLeader from "../../assets/images/anonPage/AnonLeader/anonP0S5ENLeader.png";
import anonP0S5ESPLeader from "../../assets/images/anonPage/AnonLeader/anonP0S5ESPLeader.png";

import anonP0S1EN from "../../assets/images/anonPage/AnonRookie/anonP0S1EN.png";
import anonP0S1ESP from "../../assets/images/anonPage/AnonRookie/anonP0S1ESP.png";
import anonP0S1PT_BR from "../../assets/images/anonPage/AnonRookie/anonP0S1PT_BR.png";
import anonP0S2EN from "../../assets/images/anonPage/AnonRookie/anonP0S2EN.png";
import anonP0S2ESP from "../../assets/images/anonPage/AnonRookie/anonP0S2ESP.png";
import anonP0S2PT_BR from "../../assets/images/anonPage/AnonRookie/anonP0S2PT_BR.png";
import anonP0S3EN from "../../assets/images/anonPage/AnonRookie/anonP0S3EN.png";
import anonP0S3ESP from "../../assets/images/anonPage/AnonRookie/anonP0S3ESP.png";
import anonP0S3PT_BR from "../../assets/images/anonPage/AnonRookie/anonP0S3PT_BR.png";
import anonP0S4EN from "../../assets/images/anonPage/AnonRookie/anonP0S4EN.png";
import anonP0S4ESP from "../../assets/images/anonPage/AnonRookie/anonP0S4ESP.png";
import anonP0S4PT_BR from "../../assets/images/anonPage/AnonRookie/anonP0S4PT_BR.png";
import anonP0S5EN from "../../assets/images/anonPage/AnonRookie/anonP0S5EN.png";
import anonP0S5ESP from "../../assets/images/anonPage/AnonRookie/anonP0S5ESP.png";
import anonP0S5PT_BR from "../../assets/images/anonPage/AnonRookie/anonP0S5PT_BR.png";
import anonP0S6AllLang from "../../assets/images/anonPage/AnonRookie/anonP0S6AllLang.png";

export const dataAccordingToPersonaForRookie = {
  0: [
    { EN: anonP0S1EN, PT: anonP0S1PT_BR, ES: anonP0S1ESP },
    { EN: anonP0S2EN, PT: anonP0S2PT_BR, ES: anonP0S2ESP },
    { EN: anonP0S3EN, PT: anonP0S3PT_BR, ES: anonP0S3ESP },
    { EN: anonP0S4EN, PT: anonP0S4PT_BR, ES: anonP0S4ESP },
    { EN: anonP0S5EN, PT: anonP0S5PT_BR, ES: anonP0S5ESP },
    { EN: anonP0S6AllLang, PT: anonP0S6AllLang, ESP: anonP0S6AllLang },
  ],
  7: [
    { EN: anonP0S1EN, PT: anonP0S1PT_BR, ES: anonP0S1ESP },
    { EN: anonP0S2EN, PT: anonP0S2PT_BR, ES: anonP0S2ESP },
    { EN: anonP0S3EN, PT: anonP0S3PT_BR, ES: anonP0S3ESP },
    { EN: anonP0S4EN, PT: anonP0S4PT_BR, ES: anonP0S4ESP },
    { EN: anonP0S5EN, PT: anonP0S5PT_BR, ES: anonP0S5ESP },
    { EN: anonP0S6AllLang, PT: anonP0S6AllLang, ESP: anonP0S6AllLang },
  ],
  8: [
    { EN: anonP0S1EN, PT: anonP0S1PT_BR, ES: anonP0S1ESP },
    { EN: anonP0S2EN, PT: anonP0S2PT_BR, ES: anonP0S2ESP },
    { EN: anonP0S3EN, PT: anonP0S3PT_BR, ES: anonP0S3ESP },
    { EN: anonP0S4EN, PT: anonP0S4PT_BR, ES: anonP0S4ESP },
    { EN: anonP0S5EN, PT: anonP0S5PT_BR, ES: anonP0S5ESP },
    { EN: anonP0S6AllLang, PT: anonP0S6AllLang, ES: anonP0S6AllLang },
  ],
  9: [
    { EN: anonP0S1EN, PT: anonP0S1PT_BR, ES: anonP0S1ESP },
    { EN: anonP0S2EN, PT: anonP0S2PT_BR, ES: anonP0S2ESP },
    { EN: anonP0S3EN, PT: anonP0S3PT_BR, ES: anonP0S3ESP },
    { EN: anonP0S4EN, PT: anonP0S4PT_BR, ES: anonP0S4ESP },
    { EN: anonP0S5EN, PT: anonP0S5PT_BR, ES: anonP0S5ESP },
    { EN: anonP0S6AllLang, PT: anonP0S6AllLang, ES: anonP0S6AllLang },
  ],
  10: [
    { EN: anonP0S1EN, PT: anonP0S1PT_BR, ES: anonP0S1ESP },
    { EN: anonP0S2EN, PT: anonP0S2PT_BR, ES: anonP0S2ESP },
    { EN: anonP0S3EN, PT: anonP0S3PT_BR, ES: anonP0S3ESP },
    { EN: anonP0S4EN, PT: anonP0S4PT_BR, ES: anonP0S4ESP },
    { EN: anonP0S5EN, PT: anonP0S5PT_BR, ES: anonP0S5ESP },
    { EN: anonP0S6AllLang, PT: anonP0S6AllLang, ES: anonP0S6AllLang },
  ],
  11: [
    { EN: anonP0S1EN, PT: anonP0S1PT_BR, ES: anonP0S1ESP },
    { EN: anonP0S2EN, PT: anonP0S2PT_BR, ES: anonP0S2ESP },
    { EN: anonP0S3EN, PT: anonP0S3PT_BR, ES: anonP0S3ESP },
    { EN: anonP0S4EN, PT: anonP0S4PT_BR, ES: anonP0S4ESP },
    { EN: anonP0S5EN, PT: anonP0S5PT_BR, ES: anonP0S5ESP },
    { EN: anonP0S6AllLang, PT: anonP0S6AllLang, ES: anonP0S6AllLang },
  ],
};

export const dataAccordingToPersonaForLeader = {
  0: [
    { EN: anonP0S1ENLeader, PT: anonP0S1PT_BRLeader, ES: anonP0S1ESPLeader },
    {
      EN: anonP0S2AllLangLeader,
      PT: anonP0S2AllLangLeader,
      ES: anonP0S2AllLangLeader,
    },
    { EN: anonP0S3ENLeader, PT: anonP0S3PT_BRLeader, ES: anonP0S3ESPLeader },
    {
      EN: anonP0S4AllLangLeader,
      PT: anonP0S4AllLangLeader,
      ES: anonP0S4AllLangLeader,
    },
    { EN: anonP0S5ENLeader, PT: anonP0S5PT_BRLeader, ES: anonP0S5ESPLeader },
  ],
  7: [
    { EN: anonP0S1ENLeader, PT: anonP0S1PT_BRLeader, ES: anonP0S1ESPLeader },
    {
      EN: anonP0S2AllLangLeader,
      PT: anonP0S2AllLangLeader,
      ES: anonP0S2AllLangLeader,
    },
    { EN: anonP0S3ENLeader, PT: anonP0S3PT_BRLeader, ES: anonP0S3ESPLeader },
    {
      EN: anonP0S4AllLangLeader,
      PT: anonP0S4AllLangLeader,
      ES: anonP0S4AllLangLeader,
    },
    { EN: anonP0S5ENLeader, PT: anonP0S5PT_BRLeader, ES: anonP0S5ESPLeader },
  ],
  8: [
    { EN: anonP0S1ENLeader, PT: anonP0S1PT_BRLeader, ES: anonP0S1ESPLeader },
    {
      EN: anonP0S2AllLangLeader,
      PT: anonP0S2AllLangLeader,
      ES: anonP0S2AllLangLeader,
    },
    { EN: anonP0S3ENLeader, PT: anonP0S3PT_BRLeader, ES: anonP0S3ESPLeader },
    {
      EN: anonP0S4AllLangLeader,
      PT: anonP0S4AllLangLeader,
      ES: anonP0S4AllLangLeader,
    },
    { EN: anonP0S5ENLeader, PT: anonP0S5PT_BRLeader, ES: anonP0S5ESPLeader },
  ],
  9: [
    { EN: anonP0S1ENLeader, PT: anonP0S1PT_BRLeader, ES: anonP0S1ESPLeader },
    {
      EN: anonP0S2AllLangLeader,
      PT: anonP0S2AllLangLeader,
      ES: anonP0S2AllLangLeader,
    },
    { EN: anonP0S3ENLeader, PT: anonP0S3PT_BRLeader, ES: anonP0S3ESPLeader },
    {
      EN: anonP0S4AllLangLeader,
      PT: anonP0S4AllLangLeader,
      ES: anonP0S4AllLangLeader,
    },
    { EN: anonP0S5ENLeader, PT: anonP0S5PT_BRLeader, ES: anonP0S5ESPLeader },
  ],
  10: [
    { EN: anonP0S1ENLeader, PT: anonP0S1PT_BRLeader, ES: anonP0S1ESPLeader },
    {
      EN: anonP0S2AllLangLeader,
      PT: anonP0S2AllLangLeader,
      ES: anonP0S2AllLangLeader,
    },
    { EN: anonP0S3ENLeader, PT: anonP0S3PT_BRLeader, ES: anonP0S3ESPLeader },
    {
      EN: anonP0S4AllLangLeader,
      PT: anonP0S4AllLangLeader,
      ES: anonP0S4AllLangLeader,
    },
    { EN: anonP0S5ENLeader, PT: anonP0S5PT_BRLeader, ES: anonP0S5ESPLeader },
  ],
  11: [
    { EN: anonP0S1ENLeader, PT: anonP0S1PT_BRLeader, ES: anonP0S1ESPLeader },
    {
      EN: anonP0S2AllLangLeader,
      PT: anonP0S2AllLangLeader,
      ES: anonP0S2AllLangLeader,
    },
    { EN: anonP0S3ENLeader, PT: anonP0S3PT_BRLeader, ES: anonP0S3ESPLeader },
    {
      EN: anonP0S4AllLangLeader,
      PT: anonP0S4AllLangLeader,
      ES: anonP0S4AllLangLeader,
    },
    { EN: anonP0S5ENLeader, PT: anonP0S5PT_BRLeader, ES: anonP0S5ESPLeader },
  ],
};
