import { Trans, useTranslation } from 'react-i18next'
import { getAvailableNamespaces } from '../../translations'
import React from 'react'
import { useStyles } from './StaticPages'
import {
    ROUTE_COOKIE_POLICY, ROUTE_COMMUNITY_GUILDINES, ROUTE_PRIVACY_POLICY,
} from '../../utilities/constants'
import { Link } from 'react-router-dom'
import {runtimeConfig} from "../../config";

const ToSLeader = () => {
    const classes = useStyles();
    const [t] = useTranslation(getAvailableNamespaces(), { react: { useSuspense: false } });

    return (
        <>
            <div className={classes.titleWrapper}>
                <h2 className={classes.title}>
                    {t('static_pages.tos_leader.0')}
                </h2>
            </div>

            <div className={classes.wrapper}>
                <div className={'row'}>
                    <div
                        className={
                            'col col-md-10 offset-md-1 col-lg-8 offset-lg-2'
                        }
                    >
                        <p>
                            <strong>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_leader.last_revised"
                                    components={{ strong: <strong /> }}
                                />
                            </strong>
                        </p>
                        <div className={classes.content}>
                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.1.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_leader.1.text"
                                    components={{
                                        strong: <strong />,
                                        a1: (
                                            <a
                                                target="_blank"
                                                href={`${ROUTE_PRIVACY_POLICY}`}
                                            />
                                        ),
                                        a2: (
                                            <a
                                                target="_blank"
                                                href={`${ROUTE_COOKIE_POLICY}`}
                                            />
                                        ),
                                    }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.2.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.2-1.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_leader.2-1.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.2-2.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_leader.2-2.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>
                            <ul>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.2-2.list.1"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.2-2.list.2"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.2-2.list.3"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.2-2.list.4"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.2-2.list.5"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                            </ul>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.2-3.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_leader.2-3.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.2-3-1.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_leader.2-3-1.text"
                                    components={{
                                        strong: <strong />,
                                        a1: (
                                            <a
                                                target="_blank"
                                                href="https://www.paypal.com/ga/webapps/mpp/ua/legalhub-full"
                                            />
                                        ),
                                        a3: (
                                            <a
                                                target="_blank"
                                                href="https://www.paxum.com/legal/platform-terms-of-use"
                                            />
                                        ),
                                        a4: (
                                            <a
                                                target="_blank"
                                                href="https://ccbill.com/policies.php"
                                            />
                                        ),
                                    }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.2-3-2.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_leader.2-3-2.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.2-4.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_leader.2-4.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.2-4-1.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_leader.2-4-1.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.2-4-2.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_leader.2-4-2.text"
                                    components={{
                                        strong: <strong />,
                                        a: (
                                            <a
                                                target="_blank"
                                                href="https://support.giphy.com/hc/en-us"
                                            />
                                        ),
                                        a2: (
                                            <a
                                                target="_blank"
                                                href="https://support.giphy.com/hc/en-us/articles/360020027752-GIPHY-User-Terms-of-Service"
                                            />
                                        ),
                                    }}
                                />
                            </p>
                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.2-5.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_leader.2-5.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.2-5-1.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_leader.2-5-1.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.2-6.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.2-6-1.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_leader.2-6-1.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.2-6-2.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_leader.2-6-2.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.2-6-3.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_leader.2-6-3.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.3.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_leader.3.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.4.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_leader.4.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.5.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.5-1.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_leader.5-1.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.5-2.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_leader.5-2.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.5-3.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_leader.5-3.text"
                                    components={{
                                        strong: <strong />,
                                        a: (
                                            <a
                                                target="_blank"
                                                href="mailto:DMCA@Morgi.org"
                                            />
                                        ),
                                    }}
                                />
                            </p>
                            <ul>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.5-3.list.1"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.5-3.list.2"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.5-3.list.3"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.5-3.list.4"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.5-3.list.5"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.5-3.list.6"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                            </ul>

                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_leader.5-3.text2"
                                    components={{ strong: <strong /> }}
                                />
                            </p>
                            <ul>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.5-3.list2.1"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.5-3.list2.2"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.5-3.list2.3"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.5-3.list2.4"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                            </ul>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.5-4.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_leader.5-4.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.6.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.6-1.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_leader.6-1.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.6-2.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_leader.6-2.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.7.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_leader.7.text"
                                    components={{
                                        strong: <strong />,
                                        a: (
                                            <Link
                                                to={ROUTE_COMMUNITY_GUILDINES}
                                            />
                                        ),
                                    }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.8.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_leader.8.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.9.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_leader.9.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>
                            <ul>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.9.list.1"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.9.list.2"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.9.list.3"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.9.list.4"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.9.list.5"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.9.list.6"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.9.list.7"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.9.list.8"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.9.list.9"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.9.list.10"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.9.list.11"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.9.list.12"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                            </ul>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_leader.9.text2"
                                    components={{
                                        strong: <strong />,
                                        a: (
                                            <a
                                                target="_blank"
                                                href="mailto:support@morgi.org"
                                            />
                                        ),
                                    }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.10.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_leader.10.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.11.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.11-1.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_leader.11-1.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>
                            <ul>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.11-1.list.1"
                                        components={{
                                            strong: <strong />,
                                            a: (
                                                <Link
                                                    to={
                                                        ROUTE_COMMUNITY_GUILDINES
                                                    }
                                                />
                                            ),
                                        }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.11-1.list.2"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.11-1.list.3"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.11-1.list.4"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.11-1.list.5"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                            </ul>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.11-2.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <ul>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.11-2.list.1"
                                        components={{
                                            strong: <strong />,
                                            a: (
                                                <Link
                                                    to={
                                                        ROUTE_COMMUNITY_GUILDINES
                                                    }
                                                />
                                            ),
                                        }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.11-2.list.2"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.11-2.list.3"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.11-2.list.4"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.11-2.list.5"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.11-2.list.6"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.11-2.list.7"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.11-2.list.8"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                            </ul>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.12.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_leader.12.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.13.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <ul>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.13.list.1"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.13.list.2"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.13.list.3"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.13.list.4"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                            </ul>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.14.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_leader.14.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_leader.14.text2"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.15.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_leader.15.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.16.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_leader.16.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.17.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_leader.17.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.18.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <ul>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.18.list.1"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.18.list.2"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.18.list.3"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.18.list.4"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.18.list.5"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.18.list.6"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.18.list.7"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                            </ul>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_leader.19.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_leader.19.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos.19.mail"
                                    components={{
                                        a: (
                                            <a
                                                href={`mailto:${runtimeConfig.customerCareEmail}`}
                                            />
                                        ),
                                    }}
                                />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ToSLeader
