import { Trans, useTranslation } from 'react-i18next'
import { getAvailableNamespaces } from '../../translations'
import React from 'react'
import { useStyles } from './StaticPages'
import { Link } from 'react-router-dom'
import { ROUTE_TOS } from '../../utilities/constants/routeNames'

const CommunityGuidlines = () => {
    const classes = useStyles()
    const [t] = useTranslation(getAvailableNamespaces(), {
        react: { useSuspense: false },
    })

    return (
        <>
            <div className={classes.titleWrapper}>
                <h2 className={classes.title}>
                    {t('static_pages.community_guidlines.title')}
                </h2>
            </div>

            <div className={classes.wrapper}>
                <div className={'row'}>
                    <div
                        className={
                            'col col-md-10 offset-md-1 col-lg-8 offset-lg-2'
                        }
                    >
                        <p>
                            <strong>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.community_guidlines.1.title"
                                    components={{ strong: <strong /> }}
                                />
                            </strong>
                        </p>
                        <p>
                            <Trans
                                t={t}
                                i18nKey="static_pages.community_guidlines.1.text"
                                components={{
                                    strong: <strong />,
                                    a: (
                                        <a
                                            target="_blank"
                                            href="https://www.morgi.org/"
                                        />
                                    ),
                                    a2: <Link to={ROUTE_TOS} />,
                                }}
                            />
                        </p>

                        <p>
                            <strong>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.community_guidlines.2.title"
                                    components={{ strong: <strong /> }}
                                />
                            </strong>
                        </p>
                        <p>
                            <Trans
                                t={t}
                                i18nKey="static_pages.community_guidlines.2.text"
                                components={{
                                    strong: <strong />,
                                    a1: <Link to={ROUTE_TOS} />,
                                }}
                            />
                        </p>

                        <p>
                            <strong>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.community_guidlines.3.title"
                                    components={{ strong: <strong /> }}
                                />
                            </strong>
                        </p>
                        <p>
                            <Trans
                                t={t}
                                i18nKey="static_pages.community_guidlines.3.text"
                                components={{
                                    strong: <strong />,
                                    a1: <Link to={ROUTE_TOS} />,
                                }}
                            />
                        </p>

                        <p>
                            <strong>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.community_guidlines.4.title"
                                    components={{ strong: <strong /> }}
                                />
                            </strong>
                        </p>
                        <p>
                            <Trans
                                t={t}
                                i18nKey="static_pages.community_guidlines.4.text"
                                components={{ strong: <strong /> }}
                            />
                        </p>

                        <p>
                            <strong>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.community_guidlines.5.title"
                                    components={{ strong: <strong /> }}
                                />
                            </strong>
                        </p>
                        <p>
                            <Trans
                                t={t}
                                i18nKey="static_pages.community_guidlines.5.text"
                                components={{
                                    strong: <strong />
                                }}
                            />
                        </p>

                        <p>
                            <strong>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.community_guidlines.6.title"
                                    components={{ strong: <strong /> }}
                                />
                            </strong>
                        </p>
                        <p>
                            <Trans
                                t={t}
                                i18nKey="static_pages.community_guidlines.6.text"
                                components={{ strong: <strong /> }}
                            />
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CommunityGuidlines
