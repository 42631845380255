import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { useTranslation } from "react-i18next";
import Button from "../../../components/Button";
import { getAvailableNamespaces } from "../../../translations";
import RookieAboutSection from "../RookieAboutSection/RookieAboutSection";
import RookieGoalSection from "../RookieGoalSection/RookieGoalSection";
import RookieAchievementSection from "../RookieAchievementSection/RookieAchievementSection";
import RookieSurpriseSection from "../RookieSurpriseSection/RookieSurpriseSection";
import RookieVideoSlider from "../RookieVideoSlider/RookieVideoSlider";

const useStyles = createUseStyles((theme) => ({
  contentContainer: {
    display: "block",
    padding: [16, 0, 0, 0],
    [theme.mUp]: {
      maxWidth: 440,
    },
    [theme.lUp]: {
      padding: [16, 0, 0, 0],
    },
  },
  content: {
    display: "grid",
    gridTemplateColumns: "1fr",
    "& > p": {
      margin: 0,
      fontSize: 14,
      color: theme.colors.darkPurple,
      opacity: 0.5,
      lineHeight: "140%",
      marginBottom: 16,
    },
    [theme.lUp]: {
      gridRowGap: 16,
    },
  },
  rookieVideo: {
    position: "relative",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "84px",
    alignItems: "center",
    margin: [8, 0],
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
    borderRadius: 10,
    overflow: "hidden",

    "& video": {
      width: "100%",
    },
  },
  playVideoText: {
    fontSize: 16,
    position: "absolute",
    top: "75%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    textAlign: "center",
    color: theme.colors.white,
    zIndex: 2,
  },
  playIcon: {
    cursor: "pointer",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 2,
  },
  fileOverlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background:
      "linear-gradient(rgba(0, 0, 0, 0.28) 0%, rgba(0, 0, 0, 0.12) 25%, rgba(0, 0, 0, 0.133) 75%, rgba(0, 0, 0, 0.67) 100%)",
    zIndex: 1,
    borderRadius: 10,
  },
  actions: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
    alignItems: "center",
    justifyContent: "center",
    [theme.mUp]: {
      display: "flex",
      justifyContent: "center",
    },
    [theme.lUp]: {
      borderTop: `1px solid rgba(0,0,0,0.1)`,
      padding: [32, 0],
    },
  },
}));

const RookieCardContent = ({ rookieProfile, onShare, redirectAction }) => {
  const { videos, full_name, username } = rookieProfile;
  const [t] = useTranslation(getAvailableNamespaces(), { useSuspense: false });
  const classes = useStyles({ video: videos?.url });
  return (
    <div
      className={classes.contentContainer}
      style={{ originY: 0, originX: 0 }}
    >
      <div className={classes.content}>

        <RookieVideoSlider video={videos} full_name={full_name} username={username} />

        <RookieAboutSection rookieProfile={rookieProfile} />

        <RookieSurpriseSection rookieProfile={rookieProfile} />

        <RookieGoalSection rookieProfile={rookieProfile} />

        <RookieAchievementSection rookieProfile={rookieProfile} />

        {/* <ThankYousRookieSection rookieProfile={rookieProfile}/> */}
      </div>

      <div className={classes.actions}>
        <Button
          data-outline
          width={"100%"}
          fontWeight={700}
          fontSize={16}
          onClick={onShare}
        >
          {t("rookie_profile_page.share")}
        </Button>
      </div>
    </div>
  );
};

export default RookieCardContent;
