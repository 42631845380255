export const runtimeConfig =
    typeof window !== 'undefined'
        ? {
            // client
            appEndpoint: window.env.appEndpoint,
            appHost: window.env.appHost,
            nodeEnv: window.env.NODE_ENV,
            facebookAppId: window.env.facebookAppId,
            googleClientId: window.env.googleClientId,
            customerCareEmail: window.env.googleClientId
        }
        : {
            // server
            appEndpoint: process.env.RAZZLE_APP_ENDPOINT,
            appHost: process.env.RAZZLE_APP_HOST,
            nodeEnv: process.env.NODE_ENV,
            facebookAppId: process.env.RAZZLE_APP_FACEBOOK_APP_ID,
            googleClientId: process.env.RAZZLE_APP_GOOGLE_CLIENT_ID,
            customerCareEmail: process.env.RAZZLE_SUPPORT_EMAIL
        };
